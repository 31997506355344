<template>
    <OModal ref="contentUrlModalRef"  >    
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="hstack gap-2">
                        <h4 class="modal-title" id="staticBackdropLabel">
                            {{ $t("Set content URL") }}
                        </h4>                        
                    </div>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body row" > 
                    <div class="col-12 ">
                        <label>{{ $t("Enter content URL") }}</label>
                        <input type="text" class="form-control" v-model="checkItemsDataObject.current.ContentURL">                          
                    </div>
                    <div class="col-12 mt-2">
                        <label>{{ $t("URL Alias") }}</label>
                        <input type="text" class="form-control" v-model="checkItemsDataObject.current.ContentURLAlias">                    
                    </div>
                </div>
                   
                <div class="modal-footer">
                    <button type="button" class="btn btn-sm btn-outline-primary" data-bs-dismiss="modal" @click="checkItemsDataObject.current.save();"  aria-label="Close">{{ $t("Close") }}</button>
                </div>
            </div>
        </div>
    </OModal>
</template>

<script setup>
import { OModal } from 'o365-ui-components';
import { ref, defineExpose } from 'vue';

const contentUrlModalRef = ref(null);

const props = defineProps({
    checkItemsDataObject: {
        type: Object,
        required: true
    }
});

async function show() {         
    contentUrlModalRef.value?.show();
}

defineExpose({ show });
</script>